export interface IProductInList {
  article: string;
  brand: { brandId: number; brand: string };
  category: {
    categoryId: number;
    category: string;
    categoryLocal: string;
    code?: string;
    images: {}[];
    parent: { categoryId: number; category: string; categoryLocal: string; popular: boolean; images: {}[] };
    popular: boolean;
  };
  colors: IProductColor[];
  miniColors?: IProductColorMini[];
  favoriteCount: number;
  fresh: boolean;
  likeCount: number;
  material: string;
  model: string;
  modelType: string;
  modelId?: number;
  productType: EProductType;
  recommended: IProductInList[];
  sex: {
    sex: string;
    sexLocal: string;
    sexId: number;
  };
}

export interface IProductColor {
  addons: IProductAddon[];
  productId: number;
  colorId: number;
  color: string;
  colorGroup: {
    colorGroupId: number;
    colorGroup: string;
    colorGroupLocal: string;
    code: string;
    position: number;
  };
  colorLocal: string;
  code: string[];
  colorPictureUrl: string;
  colorName: string;
  price: IProductPrice;
  pictures: IProductPicture[];
  look?: IProductInList[];
  maintenance: IProductMaintenance[];
  modelLength: string;
  modelSize: string;
  shareUrl: string;
  sale: boolean;
  fresh: boolean;
  outlet: boolean;
  shipmentRapid: boolean;
  sizes: IProductSize[];
}

export interface IProductPrice {
  priceId: number;
  price: string;
  priceDiscount: string;
  priceMinOf30Days: string;
  currencyName: string;
  campaignId: number;
  priceDiscountCustomerOnly: string;
  campaignIdCustomerOnly:number;
}

export interface IProductColorMini {
  colorPictureUrl: string;
  productId: number;
  colorCodes: string[];
}


export interface IProductSize {
  sizeId: number;
  size: string;
  stockQty: number;
}

export interface IProductPicture {
  pictureId: number;
  orderPosition: number;
  urlOriginal: string;
  urlThumbnail: string;
  urlMedium: string;
  urlLarge: string;
  urlFull: string;
  urlMicro: string;
  urlLargex: string;
  video: boolean;
}

export interface IProductItems {
  items: any;
  desktopItemsCount: number | null;
  productDetailSlug: string;
  wrapperListName: string | JSX.Element;
}

interface IProductMaintenance {
  maintenanceId: number;
  maintenance: string;
  maintenanceLocal: string;
  maintenanceImageUrl: string;
  maintenanceCode: string;
}

interface IProductAddon {
  addonId: number;
  addon: string;
  addonLocal: string;
}

export enum EProductType {
  deleted = 'DELETED',
  normal = 'NORMAL',
  outOfCache = 'OUT_OF_CACHE'
}
